import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/render/project/src/src/components/layout.js";
import { Link } from 'gatsby';
import Layout from '../components/layout';
import SEO from '../components/seo';
import hero from "../images/doux/hero.webp";
import asset from "../images/doux/asset.webp";
import heyo from "../images/doux/heyo.webp";
import trip from "../images/doux/trip.webp";
import system from "../images/doux/system.webp";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEO title="Doux Designs" keywords={['Seraph', 'kumar', 'designer', 'user', 'experience', 'product', 'interaction', 'android', 'ios', 'b2b', 'saas', 'web', 'app', 'platform', 'gatsby', 'application', 'react']} mdxType="SEO" />
    <img height="auto" loading="eager" src={hero} alt="Mockup of scientific software." className="hero" />  <br />
    <h1>{`Designing for Millions`}</h1>
    <div className="nda"> Content is limited and altered to abide by NDAs </div>
    <h2>{`Doux Designs`}</h2>
    <p>{`When I was a freelance UI designer, I would do design. So I named my business Doux Designs. That’s it. That’s why I chose the name.`}</p>
    <p>{`I usually worked with startups on products like:`}</p>
    <ul>
      <li parentName="ul">{`Construction management platform, designed for the Australian government. `}</li>
      <li parentName="ul">{`Word processing app for Android OS.`}</li>
      <li parentName="ul">{`E-reader app for Android OS.`}</li>
      <li parentName="ul">{`American ride-sharing app for iOS.`}</li>
      <li parentName="ul">{`African ride-sharing app for Android OS.`}</li>
      <li parentName="ul">{`Power management app for Android OS.`}</li>
      <li parentName="ul">{`Game console from China.`}</li>
      <li parentName="ul">{`SMS app for Android OS.`}</li>
      <li parentName="ul">{`Messenger app for iOS and Android OS.`}</li>
    </ul>
    <img height="auto" loading="lazy" src={asset} alt="Asset Vision App" className="center" />
    <div className="caption">Asset Vision's Android OS app.</div>
    <img height="auto" loading="lazy" src={trip} alt="tripBuddy App" className="center" />
    <div className="caption">tripBuddy's iOS app.</div>
    <img height="auto" loading="lazy" src={heyo} alt="Heyo App" className="center" />
    <div className="caption">Heyo's iOS app.</div>
    <img height="600px" loading="lazy" src={system} alt="Design System" className="center" />
    <div className="caption">Back then, I made design systems in photoshop and delivered them as PDFs. This one was for Asset Vision.</div>
    <Link to="/" mdxType="Link">Home</Link>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      